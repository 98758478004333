/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Card, CardActions, CardContent, Grid, Typography} from '@mui/material';
import heroImage from '../images/welcome-hero-image.svg';
import {DefaultLayout as MDXLayout} from "../components/DefaultLayout";
export {Head} from '../components/DefaultLayout';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h5: "h5",
    h2: "h2",
    p: "p",
    h6: "h6"
  }, _provideComponents(), props.components), {Block, Hero, HeroImage, HeroContent, TextGradient, ClientBar, FeatureGrid, CTALink, TestimonialGrid, TestimonialGridItem, WhyM2north, Spacer, CTAContactForm} = _components;
  if (!Block) _missingMdxReference("Block", true);
  if (!CTAContactForm) _missingMdxReference("CTAContactForm", true);
  if (!CTALink) _missingMdxReference("CTALink", true);
  if (!ClientBar) _missingMdxReference("ClientBar", true);
  if (!FeatureGrid) _missingMdxReference("FeatureGrid", true);
  if (!Hero) _missingMdxReference("Hero", true);
  if (!HeroContent) _missingMdxReference("HeroContent", true);
  if (!HeroImage) _missingMdxReference("HeroImage", true);
  if (!Spacer) _missingMdxReference("Spacer", true);
  if (!TestimonialGrid) _missingMdxReference("TestimonialGrid", true);
  if (!TestimonialGridItem) _missingMdxReference("TestimonialGridItem", true);
  if (!TextGradient) _missingMdxReference("TextGradient", true);
  if (!WhyM2north) _missingMdxReference("WhyM2north", true);
  return React.createElement(React.Fragment, null, React.createElement(Block, {
    color: 'neutral'
  }, React.createElement(Hero, null, React.createElement(HeroImage, {
    src: heroImage
  }), React.createElement("center", null, React.createElement(HeroContent, null, React.createElement(_components.h1, null, React.createElement(TextGradient, null, "Global procurement"), " as a service."), React.createElement(_components.h5, null, "Join the list of companies who send and receive business transactions every day."))))), "\n", React.createElement(Block, {
    color: 'silver',
    maxWidth: false,
    style: {
      padding: 0
    }
  }, React.createElement(ClientBar)), "\n", React.createElement(Block, {
    color: 'sky'
  }, React.createElement(FeatureGrid, {
    columns: 4,
    features: [{
      icon: 'compare_arrow',
      title: 'Cross-standard'
    }, {
      icon: 'domain',
      title: 'Cross-industry'
    }, {
      icon: 'sports_score',
      title: 'Highly-experienced'
    }, {
      icon: 'tips_and_updates',
      title: 'Innovative'
    }]
  })), "\n", React.createElement(Block, {
    color: 'neutral'
  }, React.createElement(Grid, {
    container: true,
    spacing: 4
  }, React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 6,
    md: 4
  }, React.createElement(Card, {
    sx: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      p: 3
    }
  }, React.createElement(CardContent, {
    sx: {
      flexGrow: 1
    }
  }, React.createElement(Typography, {
    color: 'text.secondary',
    variant: 'overline'
  }, "Products"), React.createElement(_components.h2, null, "Missing Link"), React.createElement(Typography, {
    color: 'text.secondary'
  }, React.createElement(_components.p, null, "Connect to all of your business partners, all at once with Missing Link. We'll do all the work. Any format. Any method. Any time."))), React.createElement(CardActions, {
    sx: {
      mx: 2
    }
  }, React.createElement(CTALink, {
    to: '/missinglink'
  }, "Find out more")))), React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 6,
    md: 4
  }, React.createElement(Card, {
    sx: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      p: 3
    }
  }, React.createElement(CardContent, {
    sx: {
      flexGrow: 1
    }
  }, React.createElement(Typography, {
    color: 'text.secondary',
    variant: 'overline'
  }, "Products"), React.createElement(_components.h2, null, "SuperSupplier"), React.createElement(Typography, {
    color: 'text.secondary'
  }, React.createElement(_components.p, null, "Find brilliant suppliers vetted by peers within your industry using our SuperSupplier search engine."))), React.createElement(CardActions, {
    sx: {
      mx: 2
    }
  }, React.createElement(CTALink, {
    to: '/supersupplier'
  }, "Find out more")))), React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 6,
    md: 4
  }, React.createElement(Card, {
    sx: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      p: 3
    }
  }, React.createElement(CardContent, {
    sx: {
      flexGrow: 1
    }
  }, React.createElement(Typography, {
    color: 'text.secondary',
    variant: 'overline'
  }, "Products"), React.createElement(_components.h2, null, "Profit Rocket"), React.createElement(Typography, {
    color: 'text.secondary'
  }, React.createElement(_components.p, null, "Automated, intelligent, actionable inter-company inventory alerts for retail."))), React.createElement(CardActions, {
    sx: {
      mx: 2
    }
  }, React.createElement(CTALink, {
    to: '/profitrocket'
  }, "Find out more")))))), "\n", React.createElement(Block, {
    color: 'silver'
  }, React.createElement(TestimonialGrid, {
    title: 'Keeping customers happy since 2001'
  }, React.createElement(TestimonialGridItem, {
    author: 'Bosch',
    content: 'M2North is unique, dynamic. They think out of the box.'
  }), React.createElement(TestimonialGridItem, {
    author: 'Macsteel',
    content: "M2North exceeded Macsteel's expectations."
  }), React.createElement(TestimonialGridItem, {
    author: 'Cashbuild',
    content: 'M2North commitment to helping Cashbuild with onboarding suppliers has been invaluable, especially considering the various ERP systems our suppliers use. They have gone above and beyond my expected requirements.'
  }), React.createElement(TestimonialGridItem, {
    author: 'Massmart',
    content: 'M2North is such a great team to work with and really go above and beyond to provide great service to their clients. They are willing to jump in any situation with expertise to assist in resolving issues, drive progress and readily accept challenges in an ever changing environment.'
  }))), "\n", React.createElement(Block, {
    color: 'sky'
  }, React.createElement(WhyM2north)), "\n", React.createElement(Block, {
    color: 'secondary'
  }, React.createElement("center", null, React.createElement(_components.h6, null, "Are you ready to take your business to the next level?"), React.createElement(Spacer, {
    size: 2
  }), React.createElement(CTAContactForm, {
    buttonSize: 'x-large'
  }))));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
